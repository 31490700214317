import JuliaReviewImage from "../../../assets/img/JuliaReviewImage.png";
import MaxReviewImage from "../../../assets/img/MaxReviewImage.png";
import DaveReviewImage from "../../../assets/img/DaveReviewImage.png";
import EllieReviewImage from "../../../assets/img/EllieReviewImage.png";
export const reviewData = [
  {
    image: JuliaReviewImage,
    name: "Julia",
    type: "Host",
    reviewText:
      "This app has been a lifesaver! With a background in business development and current psychology studies, HapiMeets allows me to apply my psychology skills while discussing business growth. The app's integrated schedule and video meeting tools streamline consultations.",
  },
  {
    image: MaxReviewImage,
    name: "Max W",
    type: "Guest",
    reviewText:
      "I was searching for people who share my interests. My first conversation with Kary about Hemingway felt like reconnecting with an old friend. Soon, I was making more friends, each one bringing their own stories and journeys into my life. ",
  },
  {
    image: DaveReviewImage,
    name: "Dave T",
    type: "Host",
    reviewText:
      "I give guitar lessons - and enjoy chatting with people from different countries. We can practice hand placement or learn a new tune. Or we can just talk heart to heart, and it's so cool!",
  },
  {
    image: EllieReviewImage,
    name: "Ellie Davis",
    type: "Guest",
    reviewText:
      "I found Alex, a host with a passion for painting, just like me. Our first video call felt like magic, blending art and hearts into a connection I'd always hoped for. HapiMeets didn't just match us; it unveiled a chapter of my life I never knew was missing.",
  },
];
