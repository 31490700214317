import Footer from "../Footer";
import Header from "../Header/Header";
import ExploreHapiMeets from "./ExploreHapiMeets/ExploreHapiMeets";
import HowItWorks from "./HowItWorks/HowItWorks";
import Features from "./Features";
import WhyHapiMeets from "./WhyHapiMeets/WhyHapiMeets";
import Download from "./Download";
import Reviews from "./Reviews/Reviews";
import HeroBanner from "./HeroBanner";
import { Box } from "@chakra-ui/react";

const Home = () => {
  return (
    <div>
      <Header />
      <Box>
        <HeroBanner />
        <ExploreHapiMeets />
        <HowItWorks />
        <Features />
        <WhyHapiMeets />
        <Download />
        <Reviews />
      </Box>
      <Footer />
    </div>
  );
};

export default Home;
