import  { useEffect, useState } from "react";
import {
  Flex,
  Stack,
  Menu,
  Divider,
  Text,
  useDisclosure,
  Box,
  IconButton,
  Collapse,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import logo from "../../assets/img/logo.svg";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { NavLink } from "react-router-dom";
import HapiMeetsLogoScroll from '../../assets/img/HapiMeetsLogoScroll.svg';
export default function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const isMobile = useBreakpointValue({base:true,lg:false})
  
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
   

   
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  const Nav = () => {
   
   
    const scrollToSection = (id: string) => {
      onToggle();
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200); // Adjust the timeout duration if needed
    };
  
    return (
      <>
        <Stack
          className="header-nav"
          direction={{ base: "column", lg: "row" }}
          spacing={{ base: "10px", lg: "20px" }}
          alignItems={{ base: "flex-end", lg: "center" }}
        >
          <Text
            fontSize='14px'
            fontWeight={500}
            cursor="pointer"
            display={{ base: "block", lg: "inline-block" }}
            className='menu-item'
            color="#555555"
            p='8px 24px'
            onClick={() => scrollToSection('how-it-works')}
            
          >
           How it works
          </Text>
          <Text
            fontSize='14px'
            fontWeight={500}
            cursor="pointer"
            className='menu-item'
            color="#555555"
            p='8px 24px'
            onClick={() => scrollToSection('features')}
          >
           Features
          </Text>
          <Text
            className='menu-item'
            fontSize='14px'
            fontWeight={500}
            cursor="pointer"
            color="#555555"
            p='8px 24px'
            onClick={() => scrollToSection('why-hapimeets')}
           
          >
          Why Hapimeets
          </Text>
          <Text
            className='menu-item'
            fontSize='14px'
            fontWeight={500}
            cursor="pointer"
            color="#555555"
            p='8px 24px'
            onClick={() => scrollToSection('download')}
          >
           Download App
          </Text>
          {
            !isMobile && <Divider orientation="vertical" height='50px'></Divider> 
          }
          
          <Flex mr={{base:'0',lg:'20'}}>
          <button
          className="button2 header-btn"
          style={{marginRight:'10px'}}
          >
            Login
          </button>
          <button className="button1 header-btn"
          >
            Sign up
          </button>
          </Flex>
          
        </Stack>
      </>
    );
  };

  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      top={0}
      left={0}
      right={0}
      zIndex={999}
      position={
        isSticky
          ? { base: "relative", lg: "fixed" }
          : { base: "relative", lg: "relative" }
      }
    >
      <Menu>
        <Flex
          style={{
            padding: "10px",
            backgroundColor: "white",
            minHeight: isSticky ? "60px" : "100px",
            transition: "min-height 0.8s ease",
          }}
          minH={"100px"}
          py={{ base: 2 }}
          px={{ base: 10 }}
          align={"center"}
        >
          {/* Logo */}
          <Flex flex={{ base: 1 }} alignItems="center">
            <Box ml={{ base: 3, lg: 20 }}>
              <NavLink to={"/"}>
                <img
                  className="logo"
                  // style={isSticky ? { width: "auto",maxWidth:'auto' } : { width: "auto" ,maxWidth:'auto' }}
                  src={isSticky? HapiMeetsLogoScroll : logo}
                  alt="logo"
                  
                />
              </NavLink>
            </Box>
          </Flex>

          {/* Hamburger Icon */}
          <Flex
            display={{ base: "block", lg: "none" }}
            ml="auto"
            alignItems="center"
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>

          {/* Navigation Links */}
          <Flex
            display={{ base: "none", lg: "flex" }}
            ml={10}
            alignItems="center"
          >
            <Stack direction={"row"} spacing={3} alignItems="center">
              <Nav />
            </Stack>
          </Flex>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <Stack
            bg={useColorModeValue("white", "gray.800")}
            p={4}
            display={{ lg: "none" }}
            textAlign="right"
          >
            <Nav />
          </Stack>
        </Collapse>
        <Divider orientation="horizontal" borderWidth="1px" />
      </Menu>
    </Box>
  );
}
