import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Image,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Parallax } from "react-scroll-parallax";
import { exploreData } from "./data";
import ExploreHapiMeetsBg from "../../../assets/img/ExploreHapiMeetsBg.svg";
const ExploreHapiMeets = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isMobileOrTablet = useBreakpointValue({
    base: true,
    md: true,
    lg: false,
  });

  const getSpeed = (index: number) => {
    switch (index) {
      case 0:
        return 10;
      case 1:
        return 20;
      case 2:
        return 30;
      case 3:
        return 40;
      default:
        return 10;
    }
  };

  const getSpeedMobile = (index: number) => {
    switch (index) {
      case 0:
        return 5;
      case 1:
        return 10;
      case 2:
        return 20;
      case 3:
        return 30;
      default:
        return 10;
    }
  };

  return (
    <Box
      id="explore-hapimeets"
      pb={{ base: "15%", md: "156px" }}
      pl={{ base: "24px", lg: "144px", md: "70px" }}
      bgImage={ExploreHapiMeetsBg}
      bgRepeat="no-repeat"
      bgPosition={{ base: "center", md: "bottom" }}
      pr={{ base: "24px", md: "70px", lg: "144px" }}
      pt={{ base: "40px", sm: "35%", md: "10%", lg: "5%" }}
      className="container-wrapper"
    >
      <Parallax speed={10}>
        <Text
          className="h1"
          pb="24px"
          w={{ base: "auto", sm: "374px", md: "auto" }}
        >
          {exploreData.title}
        </Text>
        <Text className="sub3" pb="24px">
          {exploreData.subtitle}
        </Text>
        <Text className="para1">{exploreData.explanation}</Text>
      </Parallax>
      <SimpleGrid
        spacing={isMobile ? 0 : 10}
        templateColumns={{
          base: "repeat(auto-fill, minmax(90%, 1fr))",
          md: "repeat(auto-fill, minmax(30%, 1fr))",
        }}
        mt={{ base: "0", lg: "64px" }}
        className="explore-card-wrapper"
      >
        {exploreData.cards.map((card, index) => (
          <Parallax
            speed={isMobileOrTablet ? getSpeedMobile(index) : getSpeed(index)}
            key={card.title}
          >
            <Card
              borderRadius="25px"
              pl="20px"
              h={{ base: "auto", lg: "324px" }}
              className="explore-card"
            >
              <Flex h="full" pr="0">
                <Box pb={{ base: "12%", md: "6%", lg: "12%" }} w="80%">
                  <CardHeader>
                    <Text className={isMobile ? "sub1" : "sub2"} pt="20px">
                      {card.title}
                    </Text>
                  </CardHeader>
                  <CardBody pt="0">
                    <Text className="para1">{card.text}</Text>
                  </CardBody>
                </Box>
                <Box display="flex" alignItems="end" mr="8%" w="44px">
                  <Image src={card.image} h="77px" />
                </Box>
              </Flex>
            </Card>
          </Parallax>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ExploreHapiMeets;
