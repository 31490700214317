import {
  Box,
  Grid,
  Text,
  Divider,
  Hide,
  useBreakpointValue,
} from "@chakra-ui/react";
import { hostData, guestData } from "./data";
import Section from "./Section";
import { Parallax } from "react-scroll-parallax";

const HowItWorks = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Parallax speed={-5}>
      <Box
        id="how-it-works"
        pl={{ base: "24px", lg: "144px", md: "70px" }}
        pr={{ base: "24px", md: "70px", lg: "144px" }}
        className="container-wrapper"
      >
        <Box backgroundColor="black" w="max-content" p="8px 16px" mb="32px">
          <Text className={isMobile ? "label3" : "label1"}>How it works!</Text>
        </Box>

        <Grid
          templateColumns={{ base: "1fr", lg: "1fr auto 1fr" }}
          gap={6}
          alignItems="start"
        >
          <Section {...hostData}></Section>
          <Hide below="sm">
            <Divider
              borderStyle="dashed"
              borderColor="#E5E5E5"
              orientation="vertical"
            ></Divider>
          </Hide>
          <Section {...guestData}></Section>
        </Grid>
      </Box>
    </Parallax>
  );
};

export default HowItWorks;
