import TeachAndSupportImage from '../../../assets/img/TeachAndSupport.png'
import LearnAndHangOutImage from '../../../assets/img/LearnAndHangOut.png'
import EarnImage from '../../../assets/img/Earn.png'
export const exploreData = {
    title: "Make new friends, learn new things!",
    subtitle: "Explore HapiMeets,",
    explanation: "where video chats transform shared interests into lasting bonds and earning opportunities. It's not just about connecting—it's about growing together, especially in common hobby communities across the globe.",
    cards:[
        {
            title: "Teach and Support",
            text: "Set up your Host profile on HapiMeets and schedule your availability. Make it easy for Guests to discover and book sessions with you as you share your knowledge or just offer support.",
            image:TeachAndSupportImage
        },
        {
            title:"Learn or Hang Out",
            text:"Set up your Guest profile on HapiMeets and search for Hosts offering expertise in your field of interest or who could provide support as conversation partners. Start booking sessions with those who share your hobby.",
            image:LearnAndHangOutImage
        },
        {
            title:"Earn",
            text:"HapiMeets Hosts can set up their hourly rate and availability. Guests have the opportunity to tip Hosts during sessions as well. For Hosts the only earning limit is determined by their own abilities to repeatedly provide value to their Guests.",
            image:EarnImage
        }
    ]
}