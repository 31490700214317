import {
  Box,
  Grid,
  GridItem,
  SimpleGrid,
  Text,
  Image,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect } from "react";
import VideoCallIcon from "../../assets/img/VideoCallsIcon.png";
import GiftIcon from "../../assets/img/GiftsIcon.png";
import VideoCallImage from "../../assets/img/VideoCallMockImage.png";
import HostChatImage from "../../assets/img/ChatMockImage.png";
import WalletImage from "../../assets/img/WalletMockImage.png";
import MeetsImage from "../../assets/img/MeetsMockImage.png";
import RevenueIcon from "../../assets/img/RevenueIcon.png";
import SmartCalendarIcon from "../../assets/img/SmartCalendarIcon.png";
import FeaturesBg from "../../assets/img/FeaturesBg.svg";
import {
  Parallax
} from "react-scroll-parallax";

const Features = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isMobileOrTablet = useBreakpointValue({
    base: true,
    md: true,
    lg: false,
  });
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in-top");
        } else {
          entry.target.classList.remove("fade-in-top");
        }
      });
    });
    const featureText = document.querySelectorAll(".feature-text");
    featureText.forEach((el) => observer.observe(el));
    // Clean up the observer on component unmount
    return () => {
      featureText.forEach((el) => observer.unobserve(el));
    };
  }, []);
  return (
    <Box bgColor="#E5E5E5">
    <Box
      id="features"
      bgColor="#E5E5E5"
      pt="96px"
      pb="96px"
      bgImage={isMobile ? "" : FeaturesBg}
      bgRepeat="no-repeat"
      bgPosition="center"
      pl={{ base: "24px", lg: "144px", md: "70px" }}
      pr={{ base: "24px", md: "70px", lg: "144px" }}
      className="container-wrapper"
    >
      <Box backgroundColor="black" w="max-content" p="8px 16px" mb="24px">
        <Text className={isMobile ? "label3" : "label1"}>Features</Text>
      </Box>
      <Box>
        <Text className="h1" mb="24px">
          Our Toolkit, Supercharged!
        </Text>
        <Text className="sub3">
          Packed with features that offer a unique experience for lasting
          connections.
        </Text>
      </Box>

      <Grid
        templateColumns={{ base: "repeat(1,1fr)", lg: "repeat(2,1fr)" }}
        mt="64px"
      >
        <GridItem alignContent="center">
          <Flex
            w={isMobileOrTablet ? "100%" : "90%"}
            mb="64px"
            flexDirection={isMobile ? "column" : "row"}
          >
            <Box mb={{ base: "20px", md: 0 }}>
              <Image src={VideoCallIcon} w="48px" h="48px" mr="24px"></Image>
            </Box>

            <Box width={isMobileOrTablet ? "100%" : "73%"}>
              <Text className="sub2 " mb="24px">
                Video Calls
              </Text>

              <Text className="para1 ">
                The app is designed with built-in video call functionality,
                ensuring users have access to a comprehensive set of tools for
                effective communication. Whether for casual chats, consultations
                or lessons, the platform provides a seamless way to engage in
                meaningful interactions, all scheduled at your convenience.
              </Text>
            </Box>
          </Flex>

          <Flex
            w={isMobileOrTablet ? "100%" : "90%"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Box mb={{ base: "20px", md: 0 }}>
              <Image src={GiftIcon} w="48px" h="48px" mr="24px"></Image>
            </Box>
            <Box width={isMobileOrTablet ? "100%" : "73%"}>
              <Text className="sub2 " mb="24px">
                Gifts & Ratings
              </Text>
              <Text className="para1 ">
                Surprise your host with gifts during a call. Our intuitive
                platform allows you to send presents directly in video calls or
                through messages. Show your gratitude by rating their enthusiasm
                and skills, a gesture that's sure to be appreciated.
              </Text>
            </Box>
          </Flex>
        </GridItem>

        <GridItem>
          <SimpleGrid
            columns={2}
            spacing={4}
            mt={{ base: "15%", lg: 0 }}
            mb={{ base: "2%", lg: 0 }}
          >
            <Parallax translateY={[40, -40]}>
              <Image src={VideoCallImage}></Image>
            </Parallax>

            <Parallax translateY={[40, -40]}>
              <Image src={HostChatImage}></Image>
            </Parallax>
          </SimpleGrid>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{ base: "repeat(1,1fr)", lg: "repeat(2,1fr)" }}
        mt="30px"
      >
        {!isMobileOrTablet ? (
          <>
            <GridItem>
              <SimpleGrid columns={2} spacing={4}>
                <Parallax translateY={[40, -40]}>
                  <Image src={WalletImage}></Image>
                </Parallax>

                <Parallax translateY={[40, -40]}>
                  <Image src={MeetsImage}></Image>
                </Parallax>
              </SimpleGrid>
            </GridItem>
            <GridItem alignContent="center">
              <Flex
                w={isMobileOrTablet ? "100%" : "90%"}
                mb="64px"
                justifyContent="end"
                flexDirection={isMobile ? "column" : "row"}
              >
                <Box>
                  <Image src={RevenueIcon} w="48px" h="48px" mr="24px"></Image>
                </Box>
                <Box width={isMobileOrTablet ? "100%" : "73%"}>
                  <Text className="sub2 -texfeaturet" mb="24px">
                    Revenue
                  </Text>
                  <Text className="para1 ">
                    Set your hourly rate for those who want to spend time in
                    your company or for the services you offer, attracting
                    serious guests who pay upfront to secure a slot with you.
                    Plus, with our app's wallet feature, you can effortlessly
                    monitor your earnings, ensuring you're always in control of
                    your finances.
                  </Text>
                </Box>
              </Flex>
              <Flex
                w={isMobileOrTablet ? "100%" : "90%"}
                justifyContent="end"
                flexDirection={isMobile ? "column" : "row"}
              >
                <Box>
                  <Image
                    src={SmartCalendarIcon}
                    w="48px"
                    h="48px"
                    mr="24px"
                  ></Image>
                </Box>
                <Box width={isMobileOrTablet ? "100%" : "73%"}>
                  <Text className="sub2 " mb="24px">
                    Smart calendar
                  </Text>
                  <Text className="para1 ">
                    We respect the value of your time, making it simple for
                    hosts to manage availability. With easy tracking and
                    adjustments, our app minimizes wasted time while ensuring a
                    reliable and respectful scheduling experience.
                  </Text>
                </Box>
              </Flex>
            </GridItem>
          </>
        ) : (
          <>
            <GridItem alignContent="center">
              <Flex
                w={isMobileOrTablet ? "100%" : "90%"}
                mb="64px"
                justifyContent="end"
                flexDirection={isMobile ? "column" : "row"}
              >
                <Box mb={{ base: "20px", md: 0 }}>
                  <Image src={RevenueIcon} w="48px" h="48px" mr="24px"></Image>
                </Box>
                <Box width={isMobileOrTablet ? "100%" : "73%"}>
                  <Text className="sub2 " mb="24px">
                    Revenue
                  </Text>
                  <Text className="para1 ">
                    Set your hourly rate for those who want to spend time in
                    your company or for the services you offer, attracting
                    serious guests who pay upfront to secure a slot with you.
                    Plus, with our app's wallet feature, you can effortlessly
                    monitor your earnings, ensuring you're always in control of
                    your finances.
                  </Text>
                </Box>
              </Flex>
              <Flex
                w={isMobileOrTablet ? "100%" : "90%"}
                justifyContent="end"
                flexDirection={isMobile ? "column" : "row"}
              >
                <Box mb={{ base: "20px", md: 0 }}>
                  <Image
                    src={SmartCalendarIcon}
                    w="48px"
                    h="48px"
                    mr="24px"
                  ></Image>
                </Box>
                <Box width={isMobileOrTablet ? "100%" : "73%"}>
                  <Text className="sub2 " mb="24px">
                    Smart calendar
                  </Text>
                  <Text className="para1 ">
                    We respect the value of your time, making it simple for
                    hosts to manage availability. With easy tracking and
                    adjustments, our app minimizes wasted time while ensuring a
                    reliable and respectful scheduling experience.
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem>
              <SimpleGrid
                columns={2}
                spacing={4}
                mt={{ base: "15%", lg: 0 }}
                mb={{ base: "2%", lg: 0 }}
              >
                <Parallax speed={isMobileOrTablet ? 10 : 150}>
                  <Image src={WalletImage}></Image>
                </Parallax>
                <Parallax speed={isMobileOrTablet ? 10 : 150}>
                  <Image src={MeetsImage}></Image>
                </Parallax>
              </SimpleGrid>
            </GridItem>
          </>
        )}
      </Grid>
    </Box>
    </Box>
  );
};

export default Features;
