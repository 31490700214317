import {
  Box,
  Card,
  CardBody,
  Image,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { whyHapiMeetsData } from "./data";
import WhyHapiMeetsBg from "../../../assets/img/WhyHapiMeetsBg.svg";
import { Parallax } from "react-scroll-parallax";

const WhyHapiMeets = () => {
  const isMobileOrTablet = useBreakpointValue({
    base: true,
    md: true,
    lg: false,
  });
  return (
    <Box
      id="why-hapimeets"
      bgImage={WhyHapiMeetsBg}
      bgRepeat={"no-repeat"}
      bgPosition="center"
      pt="96px"
      pb="96px"
      pl={{ base: "24px", lg: "144px", md: "70px" }}
      pr={{ base: "24px", md: "70px", lg: "144px" }}
      className="container-wrapper"
    >
      <Text className="h1-black" mb="8px">
        Why HapiMeets?
      </Text>
      {whyHapiMeetsData.map((data, index) => (
        <Box key={index}>
          <Box
            backgroundColor="black"
            w="max-content"
            p="8px 16px"
            mb="32px"
            mt="40px"
          >
            <Text className={isMobileOrTablet ? "label3" : "label1"}>
              {data.title}
            </Text>
          </Box>
          <Parallax
            translateX={
              !isMobileOrTablet ? (index === 0 ? [30, -30] : [-30, 30]) : [0, 0]
            }
            translateY={isMobileOrTablet ? [10, -10] : [0, 0]}
          >
            <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={8}>
              {data.cards.map((card, index) => (
                <Card padding="20px" borderRadius="20px" key={index}>
                  <CardBody>
                    <Box>
                      <Image
                        src={card.image}
                        mb="16px"
                        w="48px"
                        h="48px"
                        className="img-dropshadow"
                        bgColor="transparent"
                      ></Image>
                    </Box>
                    <Text className="sub2" mb="24px">
                      {card.cardTitle}
                    </Text>
                    <Text className="para1">{card.cardBody}</Text>
                  </CardBody>
                </Card>
              ))}
            </SimpleGrid>
          </Parallax>
        </Box>
      ))}
    </Box>
  );
};

export default WhyHapiMeets;
