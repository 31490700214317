import SmartCalendarIcon from "../../../assets/img/SmartCalendarIcon.png";
import SecureIcon from "../../../assets/img/SecureIcon.png";
import RevenueIcon from "../../../assets/img/RevenueIcon.png";
import DiverseCommunityIcon from "../../../assets/img/DiverseCommunityIcon.png";
import TimerIcon from "../../../assets/img/TimerIcon.png";
import BudgetIcon from "../../../assets/img/BudgetIcon.png";

export const whyHapiMeetsData = [
  {
    title: "For Hosts",
    cards: [
      {
        image: SmartCalendarIcon,
        cardTitle: "Integrated Booking Solutions",
        cardBody:
          "HapiMeets offers hosts advanced session management tools like a booking calendar and adjustable session rates. This integrated approach guarantees smooth scheduling and secure payments, making online interactions not only more efficient but also highly rewarding.",
      },
      {
        image: SecureIcon,
        cardTitle: "Secure and Private Connections",
        cardBody:
          "Your privacy is paramount at HapiMeets. With end-to-end encryption and concealed contact details, we ensure a safe environment for connecting with Guests. Our security measures prevent unwanted contact and ensure interactions are professional.",
      },
      {
        image: RevenueIcon,
        cardTitle: "Enhanced Earning Potential",
        cardBody:
          "Maximize your earnings through multiple revenue streams on HapiMeets. Aside from regular session fees, receive tips in the form of gifts directly through the app. High ratings and demonstrated expertise in your sessions can significantly increase your visibility and allow you to command higher rates.",
      },
    ],
  },
  {
    title: "For Guests",
    cards: [
      {
        image: DiverseCommunityIcon,
        cardTitle: "Diverse Community",
        cardBody:
          "Dive into a vast pool of pleasant and knowledgeable hosts ready to share their time and skills on HapiMeets. Our community offers a wide range of experts in various fields, providing guests with numerous options to learn new skills or enhance existing ones through focused, one-on-one sessions.",
      },
      {
        image: TimerIcon,
        cardTitle: "Flexible and Focused Interaction",
        cardBody:
          "Each session on HapiMeets is tailored to provide focused, guided communication. Guests have the flexibility to opt out if a session doesn’t meet their expectations, with the ability to select another host based on hourly rates. This feature ensures a personalized and positive experience.",
      },
      {
        image: BudgetIcon,
        cardTitle: "Budget-Friendly Choices",
        cardBody:
          "At HapiMeets, you control your skill acquiring journey. Choose sessions that fit your budget and schedule with hosts who meet your specific needs. This affordability and flexibility make it easier than ever to invest in personal growth and development.",
      },
    ],
  },
];
