import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Slider from "react-slick";
import { reviewData } from "./data";

const Reviews = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isMobileOrTablet = useBreakpointValue({
    base: true,
    md: true,
    lg: false,
  });

  const dotsNum = () => {
    if (reviewData.length > 4 || isMobileOrTablet) {
      return true;
    } else return false;
  };

  const settings = {
    dots: dotsNum(),
    infinite: false,
    speed: 500,
    slidesToShow: isMobileOrTablet ? 1 : 4,
    slidesToScroll: 1,
  };

  return (
    <Box
      pl={{ base: "24px", lg: "144px", md: "70px" }}
      pr={{ base: "24px", md: "70px", lg: "144px" }}
      pt="96px"
      pb="96px"
      className="container-wrapper"
    >
      <Text className={isMobile ? "sub1" : "sub2"} mb="48px">
        Hear from our Hosts and Guests
      </Text>
      <Slider {...settings}>
        {reviewData.map((data, index) => (
          <Box key={index} p={4}>
            <Card>
              <CardHeader display="flex">
                <Box width="60px" height="60px" mr="16px" borderRadius="50%">
                  <Image src={data.image} borderRadius="50%" />
                </Box>
                <Box>
                  <Text>{data.name}</Text>
                  <Text>{data.type}</Text>
                </Box>
              </CardHeader>
              <CardBody>
                <Text>{data.reviewText}</Text>
              </CardBody>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Reviews;
