import {
  Box,
  Flex,
  Image,
  Text,
  useBreakpointValue
} from "@chakra-ui/react";
import DownloadIcon from "../../assets/img/DownloadIcon.svg";
import DownloadQRImage from "../../assets/img/DownloadQRImage.png";
import DownloadIOS from "../../assets/img/DownloadIOS.png";
import DownloadAndroid from "../../assets/img/DownloadAndroid.png";
const Download = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box bgColor="#3D4049">
    <Flex
      
      flexDirection={{ base: "column", sm: "row" }}
      id="download"
      pt="96px"
      pb="96px"
      pl={{ base: "24px", lg: "144px", md: "70px" }}
      pr={{ base: "24px", md: "70px", lg: "144px" }}
      alignItems="center"
      className="container-wrapper"
    >
      <Box mr="64px" alignSelf="baseline">
        <Image src={DownloadIcon}></Image>
      </Box>
      <Box w={{ base: "100%", sm: "54%" }}>
        <Text className={isMobile ? "h2-mobile" : "h1"} mb="32px">
          Download app
        </Text>
        <Text className="sub3" mb="32px" color="white">
          Loaded with extensive features, our app is available on both Android
          and iOS platforms.
        </Text>
        <Text className="para1" color="white">
          Enjoy our web app, and for convenience on the go, try our mobile
          version available on Android and iOS—it makes your experience even
          more personal and accessible wherever you are.
        </Text>
      </Box>
      <Box
        width={{ base: "100%", sm: "111px" }}
        ml={{ base: "0", sm: "auto" }}
        mt={{ base: "40px", sm: 0 }}
      >
        <Image
          width={{ base: "128px", sm: "111px" }}
          src={DownloadQRImage}
          mb="24px"
          m="0 auto"
        ></Image>
        <Box
          display="flex"
          flexDirection={{ base: "row", sm: "column" }}
          justifyContent="space-evenly"
          mt={{ base: "40px", sm: "24px" }}
        >
          <Image
            src={DownloadIOS}
            mb={{ base: 0, sm: "24px" }}
            w={{ base: "40%", md: "100%" }}
          ></Image>
          <Image src={DownloadAndroid} w={{ base: "40%", md: "100%" }}></Image>
        </Box>
      </Box>
    </Flex>
    </Box>
  );
};

export default Download;
