import React from "react";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <Box
        minHeight={124}
        bg="#161C2D"
        display={{ base: "none", md: "flex" }}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
        textAlign={{ xs: "center", sm: "left" }}
        color="white"
        fontSize={{ xs: "12px", sm: "8px" }}
      >
        <Box
          mb={{ xs: 2, sm: 0 }}
          pl={{ lg: 20, sm: 1 }}
          fontSize={{ lg: 14, sm: 8 }}
        >
          <HStack>
            <Box fontFamily="Inter" fontWeight={400}>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Box>
            <Box fontFamily="Inter" fontWeight={400}>
              <Link to="/terms-of-use">| Terms of Use</Link>
            </Box>
          </HStack>
        </Box>
        <Box
          fontFamily="Inter"
          pr={{ lg: 20, sm: 1 }}
          fontWeight={400}
          fontSize={{ lg: 14, sm: 8 }}
        >
          <Text>Hapimeets PTE. LTD. © 2024. All Rights Reserved.</Text>
        </Box>
      </Box>
      <Box
        minHeight={75}
        bg="#161C2D"
        display={{ base: "inline-black", md: "none" }}
        color="white"
      >
        <Box pt={5} fontFamily="Inter" textAlign="center" fontWeight={400}>
          <VStack>
            <Text fontSize={"12px"}>
              Hapimeets PTE. LTD. © 2024. All Rights Reserved.
            </Text>
            <Text fontSize={"12px"}>
              {" "}
              <Link to="/privacy-policy">Privacy Policy</Link>{" "}
              <Link to="/terms-of-use">| Terms of Use</Link>
            </Text>
          </VStack>
        </Box>
      </Box>
    </>
  );
}
