import {
  Box,
  GridItem,
  HStack,
  Text,
  Image,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Parallax } from "react-scroll-parallax";

interface SectionProps {
  title: string;
  description: {
    heading: string;
    subheading: string;
    body: string;
  };
  images: string[];
  subSections: {
    title: string;
    body: string;
  }[];
}
const Section = ({ title, description, images, subSections }: SectionProps) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show-image");
        } else {
          entry.target.classList.remove("show-image");
        }
      });
    });
    const observerText = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
        } else {
          entry.target.classList.remove("fade-in");
        }
      });
    });

    const textElements = document.querySelectorAll(".how-it-works-image");
    const howText = document.querySelectorAll(".how-text");
    textElements.forEach((el) => observer.observe(el));
    howText.forEach((el) => observerText.observe(el));

    // Clean up the observer on component unmount
    return () => {
      textElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  const isMobileOrTablet = useBreakpointValue({
    base: true,
    md: true,
    lg: false,
  });
  return (
    <GridItem
      w="95%"
      {...(title === "Be our Guest!" && !isMobileOrTablet
        ? { marginLeft: "auto" }
        : "")}
    >
      <Text className="h1" mb="24px">
        {title}
      </Text>
      <Text className="sub3" mb="24px">
        {description.heading}
        <br></br>
        {description.subheading}
      </Text>
      <Text className="para1">{description.body}</Text>
      <HStack className="how-it-works-imagewrapper">
        {images.map((image, index) => (
          <Box mt="64px" mb="64px" key={image}>
            <Image
              src={image}
              borderRadius={40}
              className="how-it-works-image"
            />
          </Box>
        ))}
      </HStack>
      <SimpleGrid columns={{ base: 1, md: 2 }}>
        {subSections.map((subSection, index) => (
          <Box
            key={index}
            w={{ base: "100%", sm: "90%" }}
            mb="64px"
            justifySelf={{
              base: "start",
              md: index % 2 === 1 ? "end" : "start",
            }}
          >
            <Parallax opacity={[0, 1]} easing="easeOutQuint">
              <Text className="sub1 how-text" mb="16px">
                {subSection.title}
              </Text>
            </Parallax>
            <Parallax opacity={[0, 1]}>
              <Text className="para2 how-text" fontSize="12px">
                {subSection.body}
              </Text>
            </Parallax>
          </Box>
        ))}
      </SimpleGrid>
    </GridItem>
  );
};

export default Section;
