import FitnessTrainingImage from "../../../assets/img/FitnessTraining.png";
import YogaMasterImage from "../../../assets/img/YogaMaster.png";
import MusicTeacherImage from "../../../assets/img/MusicTeacher.png";
import Guest1Image from "../../../assets/img/Guest1.png";
import Guest2Image from "../../../assets/img/Guest2.png";
import Guest3Image from "../../../assets/img/Guest3.png";

export const hostData = {
  title: "Be our Host!",
  description: {
    heading: "Earning Made Simple,",
    subheading: "All-in-One Setup.",
    body: "Forget the hassle of multiple tools - our platform offers everything you need to start earning money online right away.",
  },
  images: [FitnessTrainingImage, YogaMasterImage, MusicTeacherImage],
  subSections: [
    {
      title: "Create Your Profile",
      body: "Set up an attractive profile detailing what you offer. Add photos and videos to highlight your skills and attract more Guests.",
    },
    {
      title: "Flexible Scheduling",
      body: "Utilize our calendar tool to mark your available hours for one-on-one sessions, making it easy for Guests to book your time.",
    },
    {
      title: "Earn Money",
      body: "Set your hourly rate for sessions - earned money accrues in your wallet, and is transferable to your bank or usable for in-app purchases.",
    },
    {
      title: "Build Credibility",
      body: "Enhance your reputation with user ratings. Higher ratings increase your visibility and appeal to more Guests.",
    },
    {
      title: "Monetize Expertise",
      body: "Benefit from multiple revenue streams—aside from session payments, receive tips and gifts directly through our integrated features.",
    },
    {
      title: "Personal Connection",
      body: "Conduct sessions over video calls to create a more personal and trustworthy environment.",
    },
  ],
};

export const guestData = {
  title: "Be our Guest!",
  description: {
    heading: "Book Sessions, Build Bonds,",
    subheading: "Better Yourself",
    body: "Explore and connect with skilled hosts who align with your interests and objectives in our vibrant community.",
  },
  images: [Guest1Image, Guest2Image, Guest3Image],
  subSections: [
    {
      title: "Discover Experts",
      body: "Browse through a vibrant community of skilled Hosts ready to share their time and knowledge. Choose those matching your goals.",
    },
    {
      title: "Text Your Favorite Hosts",
      body: "You can chat with different Hosts, get to know them before booking a session, and ensure you like their personality before committing to a video session.",
    },
    {
      title: "Book Sessions",
      body: "Use our intuitive calendar to find available slots and book live sessions with Hosts at your convenience.",
    },
    {
      title: "Personal Experience",
      body: "All interaction takes place over video calls, offering a personal touch and the opportunity to build rapport with your Host.",
    },
    {
      title: "Rate Your Experience",
      body: "After each session, rate your experience and provide tips or gifts as tokens of appreciation, fostering a positive environment.",
    },
    {
      title: "Learning to Earning",
      body: "Transition from Guest to Host by sharing your own skills, earning money, and contributing to the community.",
    },
  ],
};
