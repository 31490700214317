import {
  Box,
  Divider,
  Flex,
  List,
  ListItem,
  Menu,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import logo from "../../../assets/img/HapiMeetsLogoScroll.svg";
import Footer from "../../Footer";
import { Link } from "react-router-dom";

const TermsOfUse = () => {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    document.body.style.scrollBehavior = "auto";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Menu>
        <Flex
          style={{ padding: "10px" }}
          minH={"80px"}
          width={{ base: "390px", md: "auto" }}
          py={{ base: 2 }}
          px={{ base: 10 }}
          align={"center"}
        >
          <Flex
            flex={{ base: 1 }}
            justify={{ base: "center", md: "space-between" }}
            alignItems="center"
            className="max-withs-frame terms-logo-wrapper"
            ml="80px"
          >
            <>
              <Link to="/">
                <img className="terms-logo" src={logo} alt="logo" />
              </Link>
            </>
            <Flex alignItems="center">
              <Flex
                display={{ base: "none", md: "flex" }}
                ml={10}
                alignItems="center"
              >
                <Stack
                  direction={"row"}
                  spacing={7}
                  mr={{ base: "0px", md: "0px" }}
                  ml={{ base: "0px", md: "0px" }}
                  alignItems="center"
                ></Stack>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Divider orientation="horizontal" borderWidth="1px" />
      </Menu>
      <Box className="terms-of-use">
        <Box
          mx="auto"
          maxWidth="1024px"
          width="100%"
          bgColor="#fff"
          className="p-frame"
          mt={10}
        >
          <Box>
            <Text className="h1" pl={2} pt={10}>
              Terms of Use
            </Text>
          </Box>
          <Box className="max-withs-frame" padding="10px" marginTop="24px">
            <Box padding="10px">
              <Box>
                <Text className="para1" pb={10}>
                  Welcome to HapiMeets - a service that brings together those who are looking for communication and tutoring on various topics and those who are happy to provide it!
                </Text>
                <Text className="para1" pb={10}>
                  The HapiMeets Terms of Use (Terms) govern the relationship between the users (user, you) of the HapiMeets application (app) and HAPIMEETS PTE. LTD (Company).
                </Text>
                <Text className="para1" pb={5}>
                  Use of the HapiMeets App signifies your acceptance of these Terms and conditions.
                </Text>
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  1. What is HapiMeets
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                HapiMeets is a service for communication between people for the purpose of developing, conducting and receiving consultations, as well as getting rid of feelings of loneliness through, among other things, live communication in video chat. Ownership and development of HapiMeets application is managed by the company HAPIMEETS PTE. LTD.
              </Box>
              <Box className="para1" pb={10}>
                The Сompany provides registered users with the technical ability to post information about themselves in the HapiMeets application by creating a personal profile. The Company also provides users with the technical ability to search and view profiles of users who have posted their data in the application for the purposes provided for in the Terms.
              </Box>
              <Box className="para1" pb={10}>
                HAPIMEETS PTE. LTD is not a participant, video chat organizer, buyer, seller, employer, intermediary, agent, representative of any user, beneficiary or other interested person in relation to communications between users. Users use the information posted in HapiMeets application to enter into communication for the provision and receipt of services through video chat communication at their own risk without direct or indirect participation or control from the Company.
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  2. Registration in HapiMeets
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                You can register in the app with your email address or through your social media profile presented in the HapiMeets login form. After registration, we will create your unique profile.
              </Box>
              <Box className="para1">
                By registering with the HapiMeets App and each time you log in to HapiMeets, you acknowledge that you are aware of and agree to our Terms of Use and
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#0055C1" }}
                >
                  Privacy Policy.
                </a>
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  3. Login to HapiMeets
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                You can log in to your HapiMeets profile (login) either with the email address provided in your profile or with the Facebook, Google or Apple account associated with your profile. In the first case, you need to enter a password, in the second you must first log in to the corresponding social network.
              </Box>
              <Box className="para1">
                It is your responsibility to maintain the confidentiality of your HapiMeets login information. If you have reason to believe that someone has gained unauthorized access to your data, please notify us immediately. All actions taken in the HapiMeets App by a person authorized with your login details will be considered as taken by you. You will be responsible for such actions.
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  4. User Information
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                The information you provide when registering an account (such as your email address) must be correct in order for the account registration to be successful.
              </Box>
              <Box className="para1" pb={10}>
                The information that you place about yourself in your profile must be up-to-date and must not violate the law and the rights of third parties. Update your HapiMeets profile as needed.
              </Box>
              <Box className="para1" pb={10}>
                In some cases, if there are signs of a violation of the rules or dishonest behavior, we may ask you to comment on this, as well as change your behavior to comply with the rules. A video image of a face may be requested to confirm that the profile belongs to a real person and is not automatically registered.
              </Box>
              <Box className="para1">
                Such actions are necessary to ensure a welcoming and positive atmosphere of our service for all its users.
              </Box>

              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  5. User Content
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                User Content is the text and images that HapiMeets users post about themselves on their profile and when writing a review.
              </Box>
              <Box className="para1" pb={10}>
                By creating your profile, you are expressing information that is viewable by other HapiMeets users. By leaving a review about another user, you express your subjective opinion and evaluate your communication with this user.The Company does not act as a distributor of user-generated content. You are the owner of all information that you post on HapiMeets. You independently create, modify, place in the public domain for an unlimited number of persons and delete content (including personal data) without our participation or consent.
              </Box>
              <Box className="para1" pb={10}>
                We strongly ask you not to place personal data of third parties in application.
              </Box>
              <Box className="para1" pb={10}>
                We do not ask for any information about third parties, except in cases of violation of the atmosphere of goodwill or the rules established by HapiMeets. If this violated your rights and you yourself reported this to the support service. In this case, we may ask you to describe the situation and the nickname of the HapiMeets user you are complaining about.
              </Box>
              <Box className="para1" pb={10}>
                The purpose for which users post information on HapiMeets is to establish contact between a potential consumer who is interested in communication via video chat and the provider of such a service. This refers to services such as conducting an online lesson, consultations or simple human communication for someone who needs it.
              </Box>
              <Box className="para1" pb={10}>
                Posting user content is allowed for informational purposes only.
              </Box>
              <Box className="para1">
                We urge our users not to post false information about themselves in order to receive additional requests for video chat communication. We encourage users not to leave false information in reviews. It is also unacceptable to “cheat” reviews, including leaving reviews for a fee that help improve the rating of the user. Collection, copying, use and any other actions in relation to user content for purposes not provided for in the Terms are prohibited.
              </Box>
              <Box className="para1">
                For our part, we try to provide our users with the most reliable and high-quality information. For example, we use the method of confirming identity in photos that are posted in the Explore feed.
              </Box>
              <Box className="para1">
                Our services also manually check photos for the feed to ensure compliance with ethical standards.
              </Box>

              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  6. User communication in HapiMeets
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                Users of HapiMeets application can exchange messages with each other. The settings of the application are such that it implies two-way communication. In the same order, users can use the appointment booking service (and send the corresponding request), as well as make a call with the possibility of video communication in order to get the communication of interest.
              </Box>
              <Box className="para1" pb={10}>
                It is forbidden to use messages and calls for any other purpose. For your safety, do not transfer communication between users to other instant messengers or other communication channels.
              </Box>
              <Box className="para1" pb={10}>
                Your personal data, such as your email address or mobile phone number, is not reflected in your profile and is not visible to other users, unless you have placed this information yourself, for example, in your bio. We strongly recommend that you do not post information about you such as personal contacts. You are responsible for posting such information yourself.
              </Box>
              <Box className="para1" pb={10}>
                We have the right to record, decrypt, analyze and store messages sent in the HapiMeets service, as well as calls and video chats made through the HapiMeets application and transcripts of such calls. Messages in HapiMeets, calls and video calls in applications are not personal. We may selectively listen to call recordings, video chats, conversations, and call transcripts to enforce HapiMeets Terms of Service, stop breaking the law, and make HapiMeets better.
              </Box>
              <Box
                bg="#E8EAED"
                pt={5}
                pl={{ base: 5, md: 12 }}
                pr={{ base: 5, md: 12 }}
              >
                <Text className="sub1" pt={5} pb={5}>
                  Special position:
                </Text>
                <Box className="para1" pb={10}>
                  We strongly recommend using Hapimeets services for educational and meaningful communication, such as organizing lessons, lectures and consultations and other types of meaningful conversations between users.
                </Box>
              </Box>

              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  7. HapiMeets communication with users
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                We can send you system notifications that cannot be unsubscribed from:
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    for example to confirm registration or inform about the restriction of access to the profile.
                  </ListItem>
                </List>
              </Box>
              <Box className="para1" pb={5}>
                As well as notifications, the deactivation of which you can control:
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    service notifications related to your activities on HapiMeets: for example, receiving or accepting a request, receiving a message from another user. Similarly, as a video chat reminder 10, 3 or 1 minute before it
                  </ListItem>
                  <ListItem>
                    marketing and information notices.
                  </ListItem>
                </List>
              </Box>
              <Box className="para1" pb={5}>
                We may send you messages to the email address provided when registering your account, to your device in the form of push notifications as well as in messages within HapiMeets application.
              </Box>
              <Box className="para1">
                You can manage service alerts, informational and marketing alerts in your profile settings. Settings may vary depending on the device.
              </Box>
              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  8. Complaints and blocking by users
                </Text>
              </Box>
              <Box className="para1" pb={5}>
              Hapimeets has a blocking and complaints process procedure to protect your privacy and your safety and well-being:
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    Block someone if you don't want to receive messages, video calls or requests from them. You can unblock them at any time using the “Blacklist” in your profile settings.
                  </ListItem>
                  <ListItem>
                    Make a complaint when you want Hapimeets to know about behavior that violates the Terms of Use, your rights or the rights of third parties. We use your feedback to help keep the community safe. We may or may not contact you directly for further information, depending on the reason.
                  </ListItem>
                </List>
              </Box>
              <Box className="para1" pb={5}>
                Hapimeets will carefully investigate and respond to the problem you have or someone else has. Use the complaint function in the app or write to the support chat or email address specified at the end of the Terms.
              </Box>
              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  9. Deleting a profile
                </Text>
              </Box>
              <Box className="para1">
                You have the right to decide at any time to delete your HapiMeets profile. In this case you need to close (delete) your profile in the application settings.
              </Box>
              <Box className="para1">
                Next, we will delete your profile if you do not use it once within two years.
              </Box>

              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  10. Obligations of users
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                By using HapiMeets and interacting with the Company, you agree to:
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    strictly comply with all legal requirements;
                  </ListItem>
                  <ListItem>
                    comply with the HapiMeets Terms of Use,
                  </ListItem>
                  <ListItem>
                    do not send spam to users;
                  </ListItem>
                  <ListItem>
                    do not automatically copy information to HapiMeets, including User Content;
                  </ListItem>

                  <ListItem>
                    do not use obscene language, images and statements that provoke cruelty, hatred or disrespect, contain threats or insults, justify illegal actions, do not comply with moral standards or business practices;
                  </ListItem>
                  <ListItem>
                    not use HapiMeets or the information posted on HapiMeets for purposes other than those specified in the Terms;
                  </ListItem>
                  <ListItem>
                    not upload or use malware in HapiMeets application;
                  </ListItem>
                  <ListItem>
                    do not take actions that may interfere with the normal operation of application.
                  </ListItem>
                </List>
              </Box>

              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  11. Special community rules.
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                The goal of Hapimeets is to help create a community where people are free from feelings of loneliness.  Violence, provocation and other similar types of behavior lead to the fact that people are afraid to create new connections with other people, as a result of which the value of developing connections and communication is reduced. Our rules exist to ensure that everyone can freely and safely create connections and a sense of belonging.
              </Box>


              <Box className="para1" pb={5}>
                Safety
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    Violent speech. It is prohibited to threaten people with violence or harm, wish them to do so, incite them to do so, or glorify them.
                  </ListItem>
                  <ListItem>
                    Militant and hateful actors. It is prohibited to deal with militant or hateful actors, or to promote their activities.
                  </ListItem>
                  <ListItem>
                    Incitement to provide intimate services. It is prohibited to incite and provoke users to use Hapimeets as a platform for providing intimate services through video chat or other services available in Hapimeets.
                  </ListItem>
                  <ListItem>
                    Sensitive media. Do not post excessively gory media, or include violent or adult content in live videos, images, or profile headers. Media depicting sexual violence and/or sexual offenses is also prohibited.
                  </ListItem>
                  <ListItem>
                    Sexual exploitation of minors. Hapimeets has a zero-tolerance policy regarding the sexual exploitation of minors.
                  </ListItem>
                  <ListItem>
                    Insults and harassment. You are prohibited from posting offensive content, deliberately harassing other users, or encouraging similar behavior.
                  </ListItem>
                  <ListItem>
                    Incitement of hatred. You must not attack others on the basis of race, ethnicity, nationality, caste, sexual orientation, gender, gender identity, religious belief, age, physical or mental disability, or serious medical condition.
                  </ListItem>
                  <ListItem>
                    Suicide. Promoting or encouraging suicide and self-harm is prohibited.
                  </ListItem>
                  <ListItem>
                    Goods and services the circulation of which is prohibited or restricted. It is prohibited to use our service for illegal purposes or to facilitate illegal activities. This includes the sale and purchase of prohibited goods and services and certain types of goods and services restricted by law, as well as the facilitation of transactions related to them.
                  </ListItem>
                </List>
              </Box>
              <Box className="para1" pb={5}>
                Confidentiality
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    Confidential information. Do not publish other people's confidential information (such as home phone number and address). It is also prohibited to threaten to disclose confidential information or induce others to commit such actions.
                  </ListItem>
                  <ListItem>
                    Hacking accounts. You may not use or attempt to use login credentials, passwords, keys, or other information to log into or otherwise gain access to another person's account, or to add, delete, or change personal information or features available therein.
                  </ListItem>
                </List>
              </Box>
              <Box className="para1" pb={5}>
                Authenticity
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    Community manipulation and spam. It is prohibited to use Hapimeets services for the purpose of artificially exaggerating or hiding information, as well as to manipulate or interfere with the actions of other application users.
                  </ListItem>
                  <ListItem>
                    Misleading and fake identities. It is prohibited to impersonate other people for the purpose of misleading, disorienting or deceiving other users. It is also prohibited to use fictitious identities if it interferes with other Hapimeets users.
                  </ListItem>
                  <ListItem>
                    Copyrights and trademarks. You may not violate the intellectual property rights of others, including copyrights and trademarks.
                  </ListItem>
                </List>
              </Box>
              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  12. Violations and consequences
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                We strive to make HapiMeets a user-friendly and secure service and therefore reserve the right to randomly verify users' compliance with the HapiMeets Terms of Use at any time and in our sole discretion. We may use automated methods for this.
              </Box>
              <Box className="para1" pb={5}>
                We have the right to take action in case of detection of violations, or actions to prepare for a violation of the law, the rights of third parties or the HapiMeets Terms of Use, either against a specific piece of content (for example, biographical text, or a specific image) or against an account or a combination of these options.
              </Box>
              <Box className="para1" pb={5}>
                Below are some enforcement actions we may take.
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    Limiting Content Visibility: If necessary, we will limit the visibility of your images or bio text that violate our policies and create a negative experience for other users. In this case, your image or text will not be visible to all participants.
                  </ListItem>
                  <ListItem>
                    Setting your account to read-only mode: If you repeatedly violate the rules, we may temporarily limit your account's ability to do so. The user can log into their account and see the feed. Аll other features of the service become unavailable to the User.
                  </ListItem>
                  <ListItem>
                    Complete account blocking and ban on using the Hapimeets application: in case of numerous gross violations of special community rules and/or ignoring previous comments on violation of community rules, you may be excluded from the list of application users and your account is blocked forever.
                  </ListItem>
                </List>
              </Box>
              <Box className="para1" pb={5}>
                We may restrict access to a profile in the event of complaints from other users regarding such profile, spamming, repeated or malicious violations of the HapiMeets Terms of Use, profile hacking, user failure to provide information at our request, inaccurate reviews, or if we notice in user behavior signs of fraud in HapiMeets, the imposition of additional services and conditions, or if the user has registered a new profile in HapiMeets instead of a previously blocked one.
              </Box>
              <Box className="para1" pb={5}>
                In exceptional cases, when the user completely eliminates the violations committed by him, we can restore access to the profile in order to maintain the loyalty of the HapiMeets user.
              </Box>
              <Box className="para1" pb={5}>
                We are not responsible for possible losses caused to users in connection with the adoption of measures to prevent and stop violations in HapiMeets. We recommend that you keep a backup copy of your content on your device.
              </Box>
              <Box className="para1" pb={5}>
                In case of violation of the law or the rights of third parties when using HapiMeets, you bear full personal and legal responsibility for your actions.
              </Box>
              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  13. Final provisions
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                Any form of interaction with HapiMeets (including viewing information) confirms your acceptance of the Terms. The Company's obligation to provide access to the functionality of the HapiMeets Application is counter to your obligation to comply with the Terms.
              </Box>
              <Box className="para1" pb={5}>
                The functionality of HapiMeets may be changed, supplemented or discontinued at any time without prior notice to users. Use of the HapiMeets Application is offered on an "as is" basis, i.e. in the form and to the extent that the Company provides the functionality of HapiMeets at the time of accessing them. We are not responsible for temporary failures and interruptions in the operation of application and the loss of information caused by them. We are not responsible for any indirect, incidental, unintentional damages, including lost profits or lost data, damage to honor, dignity or business reputation caused in connection with the use of HapiMeets.
              </Box>
              <Box className="para1" pb={5}>
                Singapore law shall apply to all disputes between the Company and users. All disputes shall be submitted to the court in accordance with the territorial jurisdiction at the location of the Company, unless otherwise provided by law. We update the HapiMeets Terms of Use as necessary. By continuing to use HapiMeets after the Terms have changed, you signify your acceptance of the changes made to them.
              </Box>
              <Box className="para1" pb={10}>
                If you have any questions about the HapiMeets Terms of Use, you can contact the Support team using the messenger in the application or by emailing us&nbsp;
                <a href={'mailto:support@hapimeets.com'} target={'_blank'} style={{ color: "#0055C1" }}>support@hapimeets.com</a>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default TermsOfUse;
