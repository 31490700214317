import BannerImage1 from "../../assets/img/Banner-img.png";
import {
  Box,
  Flex,
  HStack,
  Text,
  Image,
  Hide,
  Show,
} from "@chakra-ui/react";
import BannerImage2 from "../../assets/img/Banner2.png";
import Slider from "react-slick";

const HeroBanner = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrow: true,
    useTransform: false,
    adaptiveHeight: true,
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 150; // Adjust the offset as needed

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box pos={"relative"}>
      <Slider {...settings} className="banner-slide-wrapper">
        <Flex
          pos={"relative"}
           h={{ base:'max-content',lg: "80vh", md: "90vh" }}
          alignItems="center"
          justifyContent="center"
          pb="36px"
          pl={{ base: "24px", lg: "144px", md: "70px" }}
          pr={{ base: "24px", lg: "144px" }}
          className="banner-slide container-wrapper"
        >
          <Box w={{ base: "100%", lg: "50%" }} h={{base:'100%',lg:'auto'}} className="banner-text-wrapper">
            <Text
              className="h1 banner-h1"
              pt={{ base: "40px",  md: "20%", lg: 0 }}
              pb={{ base: "40px", md: "24px" }}
              h={{ base: "auto" }}
            >
              You don't
              <br />
              have to be lonely!
            </Text>

            <Show below="lg">
              <Image src={BannerImage1} className="banner-image-ipad" />
            </Show>
            <Text className="sub3" pb="24px">
              Join our vibrant community
            </Text>
            <Text className="para1" pb={{ base: "40px", lg: "80px" }}>
              where loneliness does not exist and where your passion and
              <Hide below="sm">
                <br></br>
              </Hide>{" "}
              skills earn you money and appreciation at the same time!
            </Text>
            <HStack className='banner-button-wrapper'>
              <button className="button1">Sign up</button>
              <button className="button2">Download App</button>
            </HStack>
          </Box>
          <Hide below="lg">
            <Box className="banner-image" w={{ base: "100%", lg: "50%" }}>
              <Image maxH="620px" src={BannerImage1} />
            </Box>
          </Hide>
        </Flex>
        <Flex
          pos={"relative"}
          h={{ base: "max-content"}}
          alignItems="center"
          justifyContent="center"
          pb="36px"
          pl={{ base: "24px", lg: "144px", md: "70px" }}
          pr={{ base: "24px", lg: "144px" }}
          className="banner-slide container-wrapper"
        >
          <Box w={{ base: "100%", lg: "50%" }} h={{base:'100%',lg:'auto'}} className="banner-text-wrapper">
            <Text
              className="h1 banner-h1" 
              pt={{ base: "40px", md: "20%", lg: 0 }}
              pb={{ base: "40px", md: "24px" }}
              h={{ base: "auto" }}
            >
              Common interests.
              <br />
              Personalized sessions.
            </Text>

            <Show below="lg">
              <Image src={BannerImage2} className="banner-image-ipad" />
            </Show>
            <Text className="sub3" pb="24px">
              Join our vibrant community
            </Text>
            <Text className="para1" pb={{ base: "40px", lg: "80px" }}>
              where loneliness does not exist and where your passion and
              <Hide below="sm">
                <br></br>
              </Hide>{" "}
              skills earn you money and appreciation at the same time!
            </Text>
            <HStack className='banner-button-wrapper'>
              <button className="button1">Sign up</button>
              <button className="button2">Download App</button>
            </HStack>
          </Box>
          <Hide below="lg">
            <Box className="banner-image" w={{ base: "100%", lg: "50%" }} display={'flex'} justifyContent={'right'}>
              <Image maxH="620px" src={BannerImage2} />
            </Box>
          </Hide>
        </Flex>
      </Slider>
      <Show above="md">
        <Box
          pos="absolute"
          bottom="10px"
          right="50%"
          className="scroll-arrow"
          cursor={"pointer"}
          onClick={() => scrollToSection("explore-hapimeets")}
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="48" height="48" rx="24" fill="#555555" />
            <path
              d="M28.6665 24.8887L23.9998 29.3332M23.9998 29.3332L19.3332 24.8887M23.9998 29.3332L23.9998 18.6665"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      </Show>
    </Box>
  );
};

export default HeroBanner;
